// Entry point for the build script in package.json

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "core-js/stable";
// Bootstrap is already imported by Niiwin, so leave this line commented out.
// import "bootstrap";
import "@fortawesome/fontawesome-free/js/all.js";

Rails.start();
ActiveStorage.start();

import "./channels";
import "./controllers";

// Images
// Reference them with the image_tag helper in views (e.g < %= image_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../assets/images", true);
const imagePath = (name) => images(name, true);
